.ServiceApplicationPage {
  display: grid;
  gap: 12px 0;
  &__Buttons {
    display: flex;
    gap: 0 12px;
    margin-top: 12px;
  }
}

.ShortForm,
.LongForm {
  display: grid;
  gap: 12px 0;

  &__Fields {
    display: grid;
    gap: 12px 0;
    margin-top: 12px;
  }

  &__Row {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0 12px;
  }
}

.Title {
  font-weight: bold;
  font-size: 16px;
}
